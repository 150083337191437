import {
    getSubscription,
    listSubscriptions
} from './queries';
import {
    createSubscription,
    deleteSubscription,
    updateSubscription,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Subscription} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceNotifications.tsx";


export type ListSubscriptions = {
    listSubscriptions: { items: Subscription[] }
}

export type GetSubscription = {
    getSubscription: Subscription
}

export type CreateSubscription = {
    createSubscription: Subscription
}

export type UpdateSubscription = {
    updateSubscription: Subscription
}

export type DeleteSubscription = {
    deleteSubscription: string
}


const client = generateClient();


export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListSubscriptions>>({
                                                                                   query: listSubscriptions,
                                                                                   variables: {filter: filter},
                                                                               });
        console.debug('in the fetch', response)
        return response.data?.listSubscriptions.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetSubscription>>({
                                                                                 query: getSubscription,
                                                                                 variables: {id: id},
                                                                             });
        return response.data?.getSubscription;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateSubscription>>({
                                                                                    query: createSubscription,
                                                                                    variables: {input: payload},
                                                                                });
        console.debug('in the service response', response);
        notifySuccess('Subscription created');
        return response.data?.createSubscription;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (id: string, record: Subscription) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateSubscription>>({
                                                                                    query: (updateSubscription),
                                                                                    variables: {input: record},
                                                                                });
        notifySuccess('Subscription updated')
        return response.data?.updateSubscription;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteSubscription>>({
                                                                                    query: (deleteSubscription),
                                                                                    variables: {input: {id: id}},

                                                                                });
        notifySuccess('Subscription deleted')
        return response.data?.deleteSubscription;
    } catch (e) {
        notifyError(e)
    }
};
