import {RecordForm} from '../forms/RecordForm';


import {useSubscription} from '../hooks/useSubscription';
import {IndexPageNavigation} from "../components/IndexPageNavigation";
import {
    FormPageLayout,
    SimplePageContentWrapper
} from "@flexinet/ui-components";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useSubscription();

    return (
        <FormPageLayout
            title='Add Subscription'
            pageNavigation={<IndexPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record} handleSubmit={createRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
