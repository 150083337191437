import {FieldProps} from "formik";

import {
    CardNumberElement,
    CardNumberElementProps
} from "@stripe/react-stripe-js";
import {
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";


export const FormikStripeCardNumber = ({
                                           field,
                                           form: {setFieldValue},
                                           ...rest
                                       }: FieldProps & CardNumberElementProps & TextInputProps) => {

    return (
        <Stack gap={'md'} my={'xl'}>
            <Title order={4}><Text>{rest.label}:</Text></Title>
            <CardNumberElement {...field} {...rest} onChange={value => setFieldValue(field.name, value)}/>
        </Stack>
    );
};

