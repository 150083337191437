import {RecordForm} from '../forms/RecordForm';


import {useInvoice} from '../hooks/useInvoice';
import {IndexPageNavigation} from "../components/IndexPageNavigation";
import {
    FormPageLayout,
    SimplePageContentWrapper
} from "@flexinet/ui-components";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useInvoice();

    return (
        <FormPageLayout
            title='Add Invoice'
            pageNavigation={<IndexPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record} handleSubmit={createRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
