import {
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import {
    Center,
    Checkbox,
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useBankAccounts} from '../hooks/useBankAccounts.ts';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {useBankAccountsSelection} from '../hooks/useBankAccountsSelection.ts';
import {
    BankAccount,
    IBankAccountTableColumn,
} from '../types';
import {useParams} from "react-router-dom";
import {I18n} from "@aws-amplify/core";
import {ModalContextProvider} from "../../../context/ModalContext.tsx";
import {RecordForm} from "../forms/RecordForm.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {allowedActions} = useVerifiedPermissions()

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {
        user,
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const cognitoUsername = user.username;


    const {
        columns,
        visibleItems,
        limit,
        loading,
        totalPages,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = useBankAccounts(customerId);


    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useBankAccountsSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: IBankAccountTableColumn[] = [];
        if (allowedActions.includes('DeleteBankAccount')) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              if (item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
                                  return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                              } else {
                                  return (<Center><Checkbox disabled={true}/></Center>);
                              }

                          },


                      },
            );
        }

        cols = cols.concat(columns);


        if (allowedActions.includes('UpdateBankAccount')) {
            cols.push({
                          title: I18n.get('Name'),
                          render: (item) => {
                              //const { updateRecord } = useUserApplication(item.id);
                              const updateRecord = (item: BankAccount) => {
                                  console.debug(item);
                              };

                              if (item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
                                  return (
                                      <ModalContextProvider
                                          title='User Application'
                                          buttonOptions={{
                                              variant: 'ghost',
                                              label: 'Edit',
                                              iconType: 'edit',
                                              size: 'small',
                                          }}
                                      >
                                          <RecordForm
                                              record={item}
                                              handleSubmit={updateRecord}
                                          />
                                      </ModalContextProvider>
                                  );
                              } else {
                                  return (
                                      <>
                                          {item.account_number}
                                      </>
                                  );

                              }
                          },
                      },
            );
        }


        return cols;

    };

    const tableHeaderColumns = useMemo(() => {
        return getColumns() as TableHeaderColumn[]
    }, [])


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={tableHeaderColumns}
                    // columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
