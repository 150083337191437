import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Grid} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper,
    TabbedComponent,
    TabComponentTab
} from '@flexinet/ui-components';
import {Profile} from '../profile/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useCustomer} from '../hooks/useCustomer.ts';
import {useCustomersSelection} from '../hooks/useCustomersSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';

import {IndexPage as BankAccountsPage} from '../../bank-accounts/pages/IndexPage.tsx'
import {IndexPage as CardsPage} from '../../cards/pages/IndexPage.tsx'
import {IndexPage as InvoiceItemsPage} from '../../customer-invoice-items/pages/IndexPage.tsx'
import {useCustomers} from "../hooks/useCustomers.ts";


export const EditPage = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter');
    }

    const {fetchRecords} = useCustomers();

    const {
        record: customer,
        fetchRecord,
        updateRecord,
    } = useCustomer(customerId);

    const {handleClearSelection} = useCustomersSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [customerId]);

    if (!customer) {
        return <PlatformLoader message='Loading customer...Please wait'/>;
    }

    const tabs = [
        {
            title: 'Cards',
            component: <CardsPage/>
        },
        {
            title: 'Bank Accounts',
            component: <BankAccountsPage/>
        },
        {
            title: 'Invoice Items',
            component: <InvoiceItemsPage/>
        },
    ] as TabComponentTab[]


    return (
        <FormPageLayout
            title={customer.name ? customer.name : customer.id}
            pageNavigation={<EditPageNavigation component={customer}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Grid>
                    <Grid.Col span={{
                        base: 12,
                        lg: 4
                    }}>
                        <Profile record={customer}/>
                    </Grid.Col>
                    <Grid.Col span={{
                        base: 12,
                        lg: 8
                    }}>

                        <SimplePageContentWrapper>
                            <TabbedComponent tabs={tabs}/>
                        </SimplePageContentWrapper>

                    </Grid.Col>
                </Grid>

                :
                <SimplePageContentWrapper>
                    <RecordForm
                        record={customer}
                        handleSubmit={updateRecord}
                    />
                </SimplePageContentWrapper>
            }


        </FormPageLayout>
    );
};
