import {FieldProps} from "formik";

import {
    AddressElement,
    AddressElementProps
} from "@stripe/react-stripe-js";
import {
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";


export const FormikStripeAddress = ({
                                        field,
                                        form: {setFieldValue},
                                        ...rest
                                    }: FieldProps & AddressElementProps & TextInputProps) => {

    return (
        <Stack gap={'md'} my={'xl'}>
            <Title order={4}><Text>{rest.label}:</Text></Title>
            <AddressElement {...field} {...rest} onChange={value => setFieldValue(field.name, value)}/>
        </Stack>
    );
};

