import {
    getInvoiceItem,
    listInvoiceItems
} from './queries';
import {
    createInvoiceItem,
    deleteInvoiceItem,
    updateInvoiceItem,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {InvoiceItem} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceNotifications.tsx";


export type ListInvoiceItems = {
    listInvoiceItems: { items: InvoiceItem[] }
}

export type GetInvoiceItem = {
    getInvoiceItem: InvoiceItem
}

export type CreateInvoiceItem = {
    createInvoiceItem: InvoiceItem
}

export type UpdateInvoiceItem = {
    updateInvoiceItem: InvoiceItem
}

export type DeleteInvoiceItem = {
    deleteInvoiceItem: string
}

const client = generateClient();

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListInvoiceItems>>({
                                                                                  query: listInvoiceItems,
                                                                                  variables: {filter: filter},
                                                                              });
        return response.data?.listInvoiceItems.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (invoiceId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetInvoiceItem>>({
                                                                                query: getInvoiceItem,
                                                                                variables: {
                                                                                    invoice: invoiceId,
                                                                                    id: id
                                                                                },
                                                                            });
        return response.data?.getInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateInvoiceItem>>({
                                                                                   query: createInvoiceItem,
                                                                                   variables: {input: payload},
                                                                               });
        console.debug('in the service response', response);
        notifySuccess('Invoice Item created');
        return response.data?.createInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (record: InvoiceItem) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateInvoiceItem>>({
                                                                                   query: (updateInvoiceItem),
                                                                                   variables: {input: record},
                                                                               });
        notifySuccess('Invoice Item updated')
        return response.data?.updateInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (invoiceId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteInvoiceItem>>({
                                                                                   query: (deleteInvoiceItem),
                                                                                   variables: {
                                                                                       input: {
                                                                                           invoice: invoiceId,
                                                                                           id: id
                                                                                       }
                                                                                   },

                                                                               });
        notifySuccess('Invoice Item deleted')
        return response.data?.deleteInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};
