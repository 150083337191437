import {
    Field,
    FormikProps
} from 'formik';
import {
    FormikRadioGroup,
    FormikSelect,
    FormikSwitch,
    FormikTextInput,
    PlatformFieldset
} from "@flexinet/ui-components";
import {Price} from "../types.ts";
import {ProductsAutocomplete} from "../../products/inputs/ProductsAutocomplete.tsx";
import {Stack} from "@mantine/core";


export const FormComponent = ({values}: FormikProps<Price>) => {

    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend={'Price'}>
                <Field
                    name='product'
                    label='Product'
                    description='Please enter the product'
                    component={ProductsAutocomplete}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='unit_amount'
                    label='Unit Amount'
                    description='Please enter the unit amount'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='currency'
                    label='Currency'
                    description='Please enter the currency'
                    data={[{
                        label: 'USD',
                        value: 'usd'
                    }]}
                    component={FormikSelect}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>
            <PlatformFieldset legend={'Billing Scheme'}>

                <Field
                    name='billing_scheme'
                    label='Billing Scheme'
                    description='Please enter the billing scheme'
                    data={[{
                        label: 'Per Unit',
                        value: 'per_unit'
                    }, {
                        label: 'Tiered',
                        value: 'tiered'
                    }]}
                    component={FormikRadioGroup}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>
            <PlatformFieldset legend={'Status'}>

                <Field
                    name='active'
                    label='Active'
                    description='Please enter if the price can be used for new products'
                    component={FormikSwitch}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>
            <PlatformFieldset legend={'Recurrence'}>

                <Field
                    name='recurring'
                    label='Recurring'
                    description='Please enter if the price is recurring'
                    component={FormikSwitch}
                    withAsterisk
                    my={'xl'}
                />

                {values.recurring ? (

                    <>
                        <Field
                            name='recurring.interval'
                            label='Interval'
                            description='Please enter the interval'
                            data={[{
                                label: 'Year',
                                value: 'year'
                            }, {
                                label: 'Month',
                                value: 'month'
                            }, {
                                label: 'Week',
                                value: 'week'
                            }, {
                                label: 'Day',
                                value: 'day'
                            }]}
                            component={FormikRadioGroup}
                            my={'xl'}
                        />

                        <Field
                            name='recurring.interval_count'
                            label='Interval Count'
                            description='How many times to repeat the charge'
                            data={[{
                                label: '1',
                                value: 1
                            }, {
                                label: '2',
                                value: 2
                            }, {
                                label: '3',
                                value: 3
                            }]}
                            component={FormikRadioGroup}
                            my={'xl'}
                        />

                    </>

                ) : null}

            </PlatformFieldset>
        </Stack>
    );
};

