import {
    getInvoice,
    listInvoices
} from './queries';
import {
    createInvoice,
    deleteInvoice,
    updateInvoice,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Invoice} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceNotifications.tsx";


export type ListInvoices = {
    listInvoices: { items: Invoice[] }
}

export type GetInvoice = {
    getInvoice: Invoice
}

export type CreateInvoice = {
    createInvoice: Invoice
}

export type UpdateInvoice = {
    updateInvoice: Invoice
}

export type DeleteInvoice = {
    deleteInvoice: string
}

const client = generateClient();

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListInvoices>>({
                                                                              query: listInvoices,
                                                                              variables: {filter: filter},
                                                                          });
        console.debug('in the fetch', response)

        return response.data?.listInvoices.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetInvoice>>({
                                                                            query: getInvoice,
                                                                            variables: {id: id},
                                                                        });
        return response.data?.getInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateInvoice>>({
                                                                               query: createInvoice,
                                                                               variables: {input: payload},
                                                                           });
        console.debug('in the service response', response);
        notifySuccess('Invoice created');
        return response.data?.createInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (id: string, record: Invoice) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateInvoice>>({
                                                                               query: (updateInvoice),
                                                                               variables: {input: record},
                                                                           });
        notifySuccess('Invoice updated')
        return response.data?.updateInvoice;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteInvoice>>({
                                                                               query: (deleteInvoice),
                                                                               variables: {input: {id: id}},

                                                                           });
        notifySuccess('Invoice deleted')
        return response.data?.deleteInvoice;
    } catch (e) {
        notifyError(e)
    }
};
