import {BankAccount,} from '../types';
import {PropertiesCard} from "../../../components/PropertiesCard.tsx";


export const Profile = ({record}: { record: BankAccount }) => {
    console.debug(record)

    const properties = [
        {
            label: 'ID',
            value: record.id as string,
            span: 6,
        }, {
            label: 'Customer',
            value: record.customer as string,
            span: 6,
        }, {
            label: 'Number',
            value: record.account_number,
            span: 6,
        }, {
            label: 'Country',
            value: record.country,
            span: 6,
        }, {
            label: 'Currency',
            value: record.currency,
            span: 6,
        }, {
            label: 'Account Holder',
            value: record.account_holder_name,
            span: 6,
        }, {
            label: 'Account Holder Type',
            value: record.account_holder_type,
            span: 6,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBankAccount',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBankAccount',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }];


    return (
        <PropertiesCard properties={properties}/>
    );


};
