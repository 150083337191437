import {Field} from 'formik';
import {FormikStripeAddress} from "../../../components/FormikStripeAddress.tsx";
import {PlatformFieldset} from "@flexinet/ui-components";
import {FormikStripeCardNumber} from "../../../components/FormikStripeCardNumber.tsx";
import {FormikStripeCardExpiry} from "../../../components/FormikStripeCardExpiry.tsx";
import {FormikStripeCardCvc} from "../../../components/FormikStripeCardCvc.tsx";


export const FormComponent = () => {

    return (
        <PlatformFieldset legend={'Credit Card'}>
            {/*
            <Field

                name='card'
                label='Card'
                description='Please enter the card information'
                component={FormikStripeCard}
                withAsterisk
                my={'xl'}
            />
            */}
            <Field
                name='address'
                label='Address'
                description='Please enter the card address'
                component={FormikStripeAddress}
                options={{mode: 'billing'}}
                withAsterisk
                my={'xl'}
            />


            <Field
                name='cardNumber'
                label='Card Number'
                description='Please enter the card number'
                component={FormikStripeCardNumber}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='expirationDate'
                label='Expiration Date'
                description='Please enter the card address'
                component={FormikStripeCardExpiry}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='cvc'
                label='CVC Code'
                description='Please enter the card CVC code'
                component={FormikStripeCardCvc}
                withAsterisk
                my={'xl'}
            />

        </PlatformFieldset>
    );
};
