import {AppLayout} from "../layout/AppLayout.tsx";
import {PublicHomePage} from "../features/home/pages/PublicHomePage.tsx";
import {SignOutPage} from "../features/home/pages/SignOutPage.tsx";
import {RequireAuth} from "../utils/RequireAuth.tsx";
import {IndexPage as DashboardPage} from "../features/dashboard/pages/IndexPage.tsx";
import {CustomersLayout} from "../features/customers/layout.tsx";
import {CardsLayout} from "../features/cards/layout.tsx";
import {BankAccountsLayout} from "../features/bank-accounts/layout.tsx";
import {InvoicesLayout} from "../features/invoices/layout.tsx";
import {SubscriptionsLayout} from "../features/subscriptions/layout.tsx";
import {PricesLayout} from "../features/prices/layout.tsx";
import {ProductsLayout} from "../features/products/layout.tsx";

export const routesConfig = [{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'customers/*',
        element: <RequireAuth><CustomersLayout/></RequireAuth>
    }, {
        path: 'cards/*',
        element: <RequireAuth><CardsLayout/></RequireAuth>
    }, {
        path: 'bank-accounts/*',
        element: <RequireAuth><BankAccountsLayout/></RequireAuth>
    }, {
        path: 'invoices/*',
        element: <RequireAuth><InvoicesLayout/></RequireAuth>
    }, {
        path: 'subscriptions/*',
        element: <RequireAuth><SubscriptionsLayout/></RequireAuth>
    }, {
        path: 'prices/*',
        element: <RequireAuth><PricesLayout/></RequireAuth>
    }, {
        path: 'products/*',
        element: <RequireAuth><ProductsLayout/></RequireAuth>
    }]
}]
