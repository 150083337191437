import {
    getBankAccount,
    listBankAccounts
} from './queries';
import {
    createBankAccount,
    deleteBankAccount,
    updateBankAccount,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {BankAccount} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceNotifications.tsx";

export type ListBankAccounts = {
    listBankAccounts: { items: BankAccount[] }
}

export type GetBankAccount = {
    getBankAccount: BankAccount
}

export type CreateBankAccount = {
    createBankAccount: BankAccount
}

export type UpdateBankAccount = {
    updateBankAccount: BankAccount
}

export type DeleteBankAccount = {
    deleteBankAccount: string
}

const client = generateClient();

export const fetchRecords = async (customerId: string) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListBankAccounts>>({
                                                                                  query: listBankAccounts,
                                                                                  variables: {customer: customerId},
                                                                              });
        return response.data?.listBankAccounts.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetBankAccount>>({
                                                                                query: getBankAccount,
                                                                                variables: {
                                                                                    customer: customerId,
                                                                                    id: id
                                                                                },
                                                                            });
        return response.data?.getBankAccount;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateBankAccount>>({
                                                                                   query: createBankAccount,
                                                                                   variables: {input: payload},
                                                                               });
        console.debug('in the service response', response);
        notifySuccess('Bank Account Created')
        return response.data?.createBankAccount;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (record: BankAccount) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateBankAccount>>({
                                                                                   query: (updateBankAccount),
                                                                                   variables: {input: record},
                                                                               });
        notifySuccess('Bank Account Updated')
        return response.data?.updateBankAccount;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteBankAccount>>({
                                                                                   query: (deleteBankAccount),
                                                                                   variables: {
                                                                                       input: {
                                                                                           customer: customerId,
                                                                                           id: id
                                                                                       }
                                                                                   },

                                                                               });
        notifySuccess('Bank Account Deleted')
        return response.data?.deleteBankAccount;
    } catch (e) {
        notifyError(e)
    }
};
