import {FieldProps} from "formik";

import {
    CardCvcElement,
    CardCvcElementProps
} from "@stripe/react-stripe-js";
import {
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";


export const FormikStripeCardCvc = ({
                                        field,
                                        form: {setFieldValue},
                                        ...rest
                                    }: FieldProps & CardCvcElementProps & TextInputProps) => {

    return (
        <Stack gap={'md'} my={'xl'}>
            <Title order={4}><Text>{rest.label}</Text></Title>
            <CardCvcElement {...field} {...rest}
                            onChange={value => setFieldValue(field.name, value)}
                            options={
                                {
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                            backgroundColor: '#fcfcfc',
                                            padding: '10px',
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }
                            }
            />
        </Stack>
    );
};

