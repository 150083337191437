import {Field} from 'formik';
import {
    FormikRadioGroup,
    FormikSelect,
    FormikTextInput,
    PlatformFieldset
} from "@flexinet/ui-components";
import {FormikStripeIban} from "../../../components/FormikStripeIban.tsx";

export const FormComponent = () => {

    return (
        <PlatformFieldset legend={'Bank Account Detail'}>

            <Field
                name='iban'
                label='IBAN'
                description='Please enter the routing number if available'
                component={FormikStripeIban}
                my={'xl'}
            />

            <Field
                name='routing_number'
                label='Routing Number'
                description='Please enter the routing number if available'
                component={FormikTextInput}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='account_number'
                label='Account Number'
                description='Please enter the account number'
                component={FormikTextInput}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='account_holder_name'
                label='Account Holder Name'
                description='Please enter the account holder name'
                component={FormikTextInput}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='account_holder_type'
                label='Account Holder Type'
                description='Please enter the account holder type'
                data={[{
                    label: 'Individual',
                    value: 'individual'
                }, {
                    label: 'Company',
                    value: 'company'
                }]}
                component={FormikRadioGroup}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='country'
                label='Country'
                description='Please enter the account country'
                data={[{
                    label: 'US',
                    value: 'US'
                }]}
                component={FormikSelect}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='currency'
                label='Currency'
                description='Please enter the account currency'
                data={[{
                    label: 'USD',
                    value: 'usd'
                }]}
                component={FormikSelect}
                withAsterisk
                my={'xl'}
            />

        </PlatformFieldset>
    );
};
