import {
    getCustomer,
    listCustomers
} from './queries';
import {
    createCustomer,
    deleteCustomer,
    updateCustomer,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Customer} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceNotifications.tsx";


export type ListCustomers = {
    listCustomers: { items: Customer[] }
}

export type GetCustomer = {
    getCustomer: Customer
}

export type CreateCustomer = {
    createCustomer: Customer
}

export type UpdateCustomer = {
    updateCustomer: Customer
}

export type DeleteCustomer = {
    deleteCustomer: string
}

const client = generateClient();

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListCustomers>>({
                                                                               query: listCustomers,
                                                                               variables: {filter: filter},
                                                                           });
        return response.data?.listCustomers.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetCustomer>>({
                                                                             query: getCustomer,
                                                                             variables: {id: id},
                                                                         });
        return response.data?.getCustomer;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateCustomer>>({
                                                                                query: createCustomer,
                                                                                variables: {input: payload},
                                                                            });
        console.debug('in the service response', response);
        notifySuccess('Customer created');
        return response.data?.createCustomer;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (id: string, record: Customer) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateCustomer>>({
                                                                                query: (updateCustomer),
                                                                                variables: {input: record},
                                                                            });
        notifySuccess('Customer updated')
        return response.data?.updateCustomer;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteCustomer>>({
                                                                                query: (deleteCustomer),
                                                                                variables: {input: {id: id}},

                                                                            });
        notifySuccess('Customer deleted')
        return response.data?.deleteCustomer;
    } catch (e) {
        notifyError(e)
    }
};
