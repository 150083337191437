import {Product,} from '../types';
import {PropertiesCard} from "../../../components/PropertiesCard.tsx";
import {Stack} from '@mantine/core';

export const Profile = ({record}: { record: Product }) => {

    const properties = [
        {
            label: 'Product ID',
            value: record.id as string,
            span: 12,
        },
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        },
        {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateProduct',
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateProduct',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];


    return (
        <Stack gap={'xl'}> <PropertiesCard properties={properties}/>
            <PropertiesCard properties={properties2}/>
        </Stack>
    );


};
