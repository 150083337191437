import {Field} from 'formik';
import {
    FormikNumberInput,
    FormikSelect,
    PlatformFieldset
} from "@flexinet/ui-components";
import {CustomersAutocomplete} from "../../customers/inputs/CustomersAutocomplete.tsx";
import {PricesAutocomplete} from "../../prices/inputs/PricesAutocomplete.tsx";
import {InvoicesAutocomplete} from "../../invoices/inputs/InvoicesAutocomplete.tsx";

export const FormComponent = () => {

    return (
        <PlatformFieldset legend={'Invoice Item'}>

            <Field
                name='customer'
                label='Customer'
                description='Please select customer'
                component={CustomersAutocomplete}
                withAsterisk
                my={'xl'}
            />


            <Field
                name='price'
                label='Price'
                description='Please enter the price'
                component={PricesAutocomplete}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='invoice'
                label='Invoice'
                description='Please enter the invoice'
                component={InvoicesAutocomplete}
                withAsterisk
                my={'xl'}
            />

            <Field
                name='quantity'
                label='Quantity'
                description='Please enter the quantity'
                component={FormikNumberInput}
                withAsterisk
                my={'xl'}
            />

            {/*    <Field*/}
            {/*        name='unit_amount'*/}
            {/*        label='Unit Amount'*/}
            {/*        description='Please enter the unit amount'*/}
            {/*        component={FormikNumberInput}*/}
            {/*        withAsterisk*/}
            {/*    />*/}


            <Field
                name='currency'
                label='Currency'
                description='Please enter the account currency'
                data={[{
                    label: 'USD',
                    value: 'usd'
                }]}
                component={FormikSelect}
                withAsterisk
                my={'xl'}
            />

        </PlatformFieldset>
    );
};
