import {Card,} from '../types';
import {PropertiesCard} from "../../../components/PropertiesCard.tsx";


export const Profile = ({record}: { record: Card }) => {
    console.debug(record)

    const properties = [
        {
            label: 'ID',
            value: record.id as string,
            span: 6,
        }, {
            label: 'Customer',
            value: record.customer,
            span: 6,
        }, {
            label: 'Name',
            value: record.name,
            span: 6,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateCard',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateCard',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }];


    return (
        <PropertiesCard properties={properties}/>
    );


};
