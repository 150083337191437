import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@flexinet/ui-components';

import {Card} from '../types';
import {useNavigate} from "react-router-dom";
import {stage} from "../../../config/stage";
import {
    useElements,
    useStripe
} from "@stripe/react-stripe-js";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<Card, 'id'>,
    handleSubmit: (values: Card) => void
}) => {
    const navigate = useNavigate()
    const stripe = useStripe();
    const elements = useElements();


    const onSubmit = async () => {

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement('card')
        if (!cardElement) {
            throw new Error('card element is require parameter')
        }

        const result = await stripe.createToken(cardElement, {});

        if (typeof result.token === 'undefined') {
            throw new Error('token is required parameter')
        }

        const payload = {
            source: result.token.id,
            id: ''
        } as Card

        handleSubmit(payload);
        navigate(`/customers/${record.customer}`);
    };

    const onCancel = () => {
        navigate(`/customers/${record.customer}`);
    };


    let initialValues = {} as Card;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent/>
                </FormWithButtons>)}
        </Formik>
    );
};
